<!--suppress NpmUsedModulesInstalled -->
<template>
<criteria-form @submit="apply" :canSubmit="canSubmit">
  <template #form>
    <b-form-group
      :label="$t('REPORTS.FILTERS.START_DATE')"
      id="input-group-datepicker-from"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <Datepicker id="datepicker-from" size="sm"
                  v-model="$v.form.period.from.$model"
                  :state="$state('period.from')"
                  aria-describedby="datepicker-feedback"
      />
      <b-form-invalid-feedback id="datepicker-2-feedback" :class="{'d-block': $state('period.from') === null}">
        <template v-if="!$v.form.period.from.required">
          {{ $t('VALIDATION.REQUIRED', { name: $t('REPORTS.FILTERS.START_DATE') }) }}
        </template>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.END_DATE')"
      id="input-group-datepicker-to"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <Datepicker id="datepicker-to" size="sm"
                  v-model="$v.form.period.to.$model"
                  :state="$state('period.to')"
                  aria-describedby="datepicker-feedback"
      />
      <b-form-invalid-feedback id="datepicker-2-feedback" :class="{'d-block': $state('period.to') === null}">
        <template v-if="!$v.form.period.to.required">
          {{ $t('VALIDATION.REQUIRED', { name: $t('REPORTS.FILTERS.START_DATE') }) }}
        </template>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.BRANCHES')"
      id="input-group-branches"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select multiple v-model="form.branches" repository="company/branches"
                     :placeholder="$t('REPORTS.FILTERS.BRANCHES')"
      />
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.CLIENTS')"
      id="input-group-clients"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select multiple v-model="form.clients" repository="company/client"
                     :placeholder="$t('REPORTS.FILTERS.CLIENTS')"
                     :optionName="(opt) => `${opt.firstName} ${opt.lastName} ${opt.phone}`"
      />
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.SERVICES_CATEGORY')"
      id="input-group-service-categories"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select multiple v-model="form.serviceCategories" repository="company/service-categories"
                     :placeholder="$t('REPORTS.FILTERS.SERVICES_CATEGORY')"
      />
    </b-form-group>
    <b-form-group
      id="input-group-services"
      label-align-md="left"
      :label="$t('REPORTS.FILTERS.SERVICES')"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select multiple v-model="form.services" repository="company/services" :placeholder="$t('REPORTS.FILTERS.SERVICES')" />
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.ORDER_STATUS')"
      id="input-group-order-statuses"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select multiple v-model="form.orderStatuses" :placeholder="$t('ORDERS.STATUS')"
                     :optionName="(opt) => opt.name"
                     :optionValue="(opt) => opt.value"
                     :items="statuses.map(e=>{return{value:e.val, name: $t(`ORDER.STATUSES.${e.val}`)}})"
      />
    </b-form-group>
    <b-form-group
      :label="$t('REPORTS.FILTERS.DATE_STEP.TITLE')"
      id="input-group-date-step"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <alioth-select v-model="form.dateStep" :placeholder="$t('ORDERS.STATUS')"
                     :optionName="(opt) => opt.name"
                     :optionValue="(opt) => opt.value"
                     :items="dateSteps.map(e=>{return{value:e.val, name: $t(`REPORTS.FILTERS.DATE_STEP.${e.val}`)}})"
      />
    </b-form-group>
  </template>
</criteria-form>
</template>

<script>
import { ArrayFormat, Choice, DateFormat } from '@/helpers/normalizers'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { STEP_DAY, STEPS } from '../../../constants/date-steps'
import { STATUSES } from '../../../constants/statuses-order'
import criteriaMacro from '../../../mixins/criteriaMacro'
import serverVuelidate from '../../../mixins/serverVuelidate'
import AliothSelect from '../../forms-items/alioth-select'
import Datepicker from '../../forms-items/Datepicker'
import CriteriaForm from '../criteria/CriteriaForm'

export default {
  name: 'SumsByOrdersCriteria',
  components: {
    CriteriaForm,
    Datepicker,
    AliothSelect,
  },
  mixins: [validationMixin, serverVuelidate, criteriaMacro],
  data() {
    return {
      statuses: STATUSES,
      dateSteps: STEPS,
      form: {
        period: {
          from: new Date(),
          to: new Date(),
        },
        branches: [],
        clients: [],
        serviceCategories: [],
        services: [],
        orderStatuses: [],
        dateStep: STEP_DAY,
      },
      normalizers: {
        branches: ArrayFormat(),
        clients: ArrayFormat(),
        serviceCategories: ArrayFormat(),
        services: ArrayFormat(),
        orderStatuses: ArrayFormat('value'),
        dateStep: Choice('value'),
        period: {
          from: DateFormat('YYYY-MM-DD 00:00'),
          to: DateFormat('YYYY-MM-DD 23:59'),
        },
      },
      validations: {
        form: {
          period: {
            from: { required },
            to: { required },
          },
        },
      },
    }
  },
}
</script>
