const STEP_DAY = 'day'
const STEP_MONTH = 'month'
const STEP_YEAR = 'year'
const STEPS = [
  { val: STEP_DAY },
  { val: STEP_MONTH },
  { val: STEP_YEAR },
]

export {
  STEP_DAY,
  STEP_MONTH,
  STEP_YEAR,
  STEPS,
}
