<template>
<report-table>
  <template #thead v-if="rows.length">
    <tr>
      <th scope="col">
        <span class=" svg-icon svg-icon-secondary svg-icon-sm">
          <inline-svg src="/media/svg/icons/General/Other1.svg" />
        </span>
      </th>
      <th scope="col">{{ $t('ORDER.START_DATE') }}</th>
      <th>{{ $t('ORDER.SUMMARY.QUANTITY') }}</th>
      <th>{{ $t('ORDER.SUMMARY.COST') }}</th>
      <th>{{ $t('ORDER.SUMMARY.FINAL_COST') }}</th>
      <th>{{ $t('ORDER.SUMMARY.DISCOUNTS') }}</th>
    </tr>
  </template>
  <template #tbody v-if="rows.length">
    <tr :key="i" v-for="(row, i) in rows">
      {{ add(`count-${row.branchName}`,row.ordersCount) }}
      {{ add(`costwd-${row.branchName}`,row.sumCostWithoutDiscount) }}
      {{ add(`costwod-${row.branchName}`,row.sumCostWithDiscount) }}
      {{ add(`costds-${row.branchName}`,row.discountSum) }}

      <td>{{ i+1 }}</td>
      <td>{{ dateFormat(row.date) }}</td>
      <td>{{ row.ordersCount }}</td>
      <td>{{ row.sumCostWithoutDiscount/100 }}</td>
      <td>{{ row.sumCostWithDiscount/100 }}</td>
      <td>{{ row.discountSum/100 }}</td>
    </tr>
    <tr class="text-primary">
      <td colspan="2">{{ $t('REPORTS.TOTAL') }}</td>
      <td :key="rows[0].branchName + 'a'">{{ stored(`count-${rows[0].branchName}`) }}</td>
      <td :key="rows[0].branchName + 'd'">{{ stored(`costwd-${rows[0].branchName}`)/100 }}</td>
      <td :key="rows[0].branchName + 'f'">{{ stored(`costwod-${rows[0].branchName}`)/100 }}</td>
      <td :key="rows[0].branchName + 'v'">{{ stored(`costds-${rows[0].branchName}`)/100 }}</td>
    </tr>

  </template>
  <template #tbody v-else>
    <tr>
      <td scope="col" class="text-center">{{ $t('REPORTS.NO_DATA') }}</td>
    </tr>
  </template>
</report-table>
</template>

<script>
import ReportTable from '../table/Table'
import { FORMAT_DATE } from '../../../helpers/validation'

export default {
  name: 'SumsByOrdersTable',
  components: { ReportTable },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FORMAT_DATE,
      loopStorage: {},
    }
  },
  computed: {
    stored() {
      return (k) => {
        return this.loopStorage[k] || 0
      }
    },
  },
  watch: {
    rows() {
      this.resetLoopStorage()
    },
  },
  methods: {
    dateFormat(val) {
      return this.$moment(val).format(FORMAT_DATE)
    },
    add(k, v) {
      if (this.loopStorage[k] === undefined) {
        this.loopStorage[k] = 0
      }
      this.loopStorage[k] += v
    },
    get(k) {
      return this.loopStorage[k] || 0
    },
    resetLoopStorage() {
      this.loopStorage = {}
    },
  },
}
</script>

<style scoped>
</style>
